
import Vue from "vue";
import { User, Document } from "@/types";
import BaseDialog from "@/components/BaseDialog.vue";
import GroupedSelect from "@/components/GroupedSelect.vue";

export default Vue.extend({
  props: ["item", "value"],
  methods: {
    submitUser(e: Event) {
      if (!(this.$refs.form as any).validate()) {
        return;
      }
      this.formData.roles = this.selectedRoles;
      if (this.item.id !== undefined) {
        this.$store.dispatch("user/updateUser", this.formData);
        this.toggleEdit();
      } else {
        this.$store.dispatch("user/addUser", {...this.formData});
        this.dialog = false;
      }
    },
    toggleEdit() {
      if (this.item.id) {
        this.edit = !this.edit;
      }
      this.formData = { ...this.item };
    },
    setRoles(selectedItems: any) {
      this.selectedRoles = selectedItems;
    }
  },
  computed: {
    dialog: {
      get(this: any): boolean {
        return this.value;
      },
      set(this: any, value: boolean): void {
        this.$emit("input", value);
      }
    },
    roles(): string[] {
      return this.$store.getters["role/roles"];
    },
  },
  watch: {
    dialog() {
      if (this.item.id) {
        this.edit = false;
      } else {
        this.edit = true;
        (this.$refs.form as any).reset();
      }
    },
    item() {
      this.formData = { ...this.item };
      this.selectedRoles = this.formData.roles;
    }
  },
  created(): void {
    this.$store.dispatch("role/getRoles");
  },
  data() {
    return {
      edit: true,
      formData: {
        id: -1,
        icsId: "",
        firstName: "",
        lastName: "",
        email: "",
        userName: "",
        roles: [],
      } as any,
      selectedRoles: [] as any,
      
    };
  },
  components: {
    "base-dialog": BaseDialog,
    "grouped-select": GroupedSelect,
  }
});
