
import Vue from "vue";
import LayoutTopMenu from "@/layouts/LayoutTopMenu.vue";
import { Task, RealEstate, User } from "../types";
import BaseDataView from "@/components/BaseDataView.vue";
import BaseTable from "@/components/BaseTable.vue";
import moment from "moment";
import debounce from "lodash/debounce";
import i18n from "../plugins/i18n";
import UserDetailModal from "../components/UserDetailModal.vue";

const Component = Vue.extend({
  created(): void {
    this.$emit("update:layout", LayoutTopMenu);
    this.$store.dispatch("user/getAllUsers");
  },
  computed: {
    users(): User[] {
      return this.$store.getters["user/usersFiltered"](this.filters);
    },
    usersLoading(): boolean {
      return this.$store.getters["user/isLoading"];
    },
    dialogItem(): User | {} {
      if (this.dialogItemId > -1) {
        return (
          this.users.find((item: User) => {
            return item.id === this.dialogItemId;
          }) || {}
        );
      } else {
        return {
          id: undefined,
          icsId: "",
          userName: "",
          email: "",
          firstName: "",
          lastName: "",
          roles: [],
          active: false,
        };
      }
    }
  },
  methods: {
    setFilters: debounce(function(this: any, filters: any): void {
      this.filters = filters;
    }, 500),
    async removeUser(userId: number, e: Event) {
      if (
        await this.$confirm(
          i18n.t("USER.DELETE_TITLE").toString(),
          i18n.t("USER.DELETE").toString()
        )
      ) {
        this.$store.dispatch("user/removeUser", userId);
      }
    },
    openDetails(id: number, e: Event) {
      e.stopPropagation();
      this.dialogItemId = id;
      this.dialog = true;
    },
    openNewUser() {
      this.dialogItemId = -1;
      this.dialog = true;
    }
  },
  data() {
    return {
      dialogItemId: -1,
      dialog: false,
      pagination: {
        itemsPerPage: 50
      },
      filters: {},
      headers: [
        {
          text: "USER.INTERNAL_ID",
          value: "id",
          align: "left",
          filterable: true,
          sortable: true
        },
        {
          text: "USER.LASTNAME",
          value: "lastName",
          align: "left",
          filterable: true,
          sortable: true
        },
        {
          text: "USER.FIRSTNAME",
          value: "firstName",
          align: "left",
          filterable: true,
          sortable: true
        },
        {
          text: "USER.USERNAME",
          value: "userName",
          align: "left",
          filterable: true,
          sortable: true
        },
        {
          text: "USER.EMAIL",
          value: "email",
          align: "left",
          filterable: true,
          sortable: true
        },
        {
          text: "USER.ICS_ID",
          value: "icsId",
          align: "left",
          filterable: true,
          sortable: true
        },
        {
          text: "USER.ACTIVE",
          value: "active",
          align: "left",
          sortable: true
        },
        {
          text: "USER.ACTIVATED_AT",
          value: "activatedAt",
          align: "left",
          filterable: true,
          sortable: true
        },
        {
          text: "USER.LAST_LOGIN",
          value: "lastLogin",
          align: "left",
          filterable: true,
          sortable: true
        },
        {
          text: "USER.CREATED_AT",
          value: "createdAt",
          align: "left",
          filterable: true,
          sortable: true
        },
        {
          text: "USER.EDIT",
          value: "",
          align: "left",
          sortable: false,
          filterable: false
        }
      ]
    };
  },
  components: {
    "base-table": BaseTable,
    "user-detail-modal": UserDetailModal,
    "base-data-view": BaseDataView
  }
});
export default Component;
